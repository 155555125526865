import { string2PreprocessingStatus } from '@features/signals-status/shared/interface/astrion-signals-status.mapper';
import { AStrionSignal, AStrionSignalDto, AStrionSignalPayload } from './astrion-signal.interface';

export const signal2payload = (signal: AStrionSignal): AStrionSignalPayload => ({
  name: signal.name,
});

export const dto2signal = (signalDto: AStrionSignalDto): AStrionSignal => ({
  ...signalDto,
  preprocessingStatus: string2PreprocessingStatus(signalDto.preprocessingStatus),
  sensorId: signalDto.sensorId,
  createdAt: new Date(signalDto.createdAt),
  date: signalDto.date ? new Date(signalDto.date) : undefined,
});

export const dto2signals = (signalsDto: AStrionSignalDto[]): AStrionSignal[] =>
  signalsDto.map(signal => dto2signal(signal));
