import { createFeature, createFeatureSelector, createSelector } from '@ngrx/store';

import { reducer } from './signal-report.reducer';
import { featureName } from './signal-report.state';
import { equipmentsFeature } from '@features/equipments/shared/store/equipments.feature';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { sensorsFeature } from '@features/sensors/shared/store/sensors.feature';
import { AStrionSignalId, AStrionSignal } from '@features/signals/shared/interface/astrion-signal.interface';
import {
  featureName as signalOverviewFeatureName,
  SignalOverviewState,
} from '@features/signal-overview/shared/store/signal-overview.state';
import { PreprocessingStatus } from '@shared/interfaces/processing-status';

const selectSignalOverview = createFeatureSelector<SignalOverviewState>(signalOverviewFeatureName);

export const signalReportFeature = createFeature({
  name: featureName,
  reducer,
  extraSelectors: ({
    selectName,
    selectSignalId,
    selectSensorId,
    selectPreprocessingStatus,
    selectPreprocessingMessage,
  }) => ({
    selectSignalSensor: createSelector(selectSensorId, sensorsFeature.selectSensors, (sensorId, sensors) => {
      return sensors.find(s => s.id === sensorId);
    }),

    selectSignalEquipment: createSelector(
      selectSensorId,
      equipmentsFeature.selectEquipments,
      (sensorId, equipments) => {
        return equipments.find(e => e.sensors.find(s => s.id === sensorId));
      }
    ),

    selectSignal: createSelector(
      selectName,
      selectSignalId,
      selectSensorId,
      selectPreprocessingStatus,
      selectPreprocessingMessage,
      selectSignalOverview,
      (
        name: string,
        signalId: AStrionSignalId | null,
        sensorId: SensorId | null,
        preprocessingStatus: PreprocessingStatus,
        preprocessingMessage: string | null,
        signalOverview: SignalOverviewState
      ): AStrionSignal | undefined => {
        if (signalId === null || sensorId === null || signalOverview.signalData === undefined) {
          return undefined;
        }

        const metadata = signalOverview.signalData.metadata;

        return {
          id: signalId,
          name,
          preprocessingStatus,
          preprocessingMessage,
          sensorId,
          createdAt: metadata.createdAt,
          date: metadata.date,
          samplingFrequency: metadata.samplingFrequency,
          frequencyToHertzRatio: metadata.frequencyToHertzRatio,
          samplesCount: metadata.samplesCount,
          description: metadata.description,
        };
      }
    ),

    selectNameWithoutExtension: createSelector(selectName, (name: string): string => {
      return name.slice(0, name.indexOf('.AStrion'));
    }),
  }),
});
