import { DataType, makeBinaryField, makeImageField } from '@astrion-webtools/graph';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

export const samplingTestCriterionBytesStorageDescriptor = (id: string, samplingFrequency: number) => {
  return makeBinaryField({
    id,
    dbName: ASTRION_INDEXEDDB_NAME,
    storeName: ASTRION_INDEXEDDB_TABLES.samplingTests,
    dstName: 'data',
    dataType: DataType.Float64,
    additionalFields: {
      xMin: 0.0,
      xMax: samplingFrequency / 2.0,
    },
  });
};

export const stationarityProjectionBytesStorageDescriptor = (id: string, length: number, maxProjection: number) => {
  return makeBinaryField({
    id,
    dbName: ASTRION_INDEXEDDB_NAME,
    storeName: ASTRION_INDEXEDDB_TABLES.stationarityTestProjection,
    dstName: 'data',
    dataType: DataType.Float64,
    additionalFields: {
      xMin: 0.0,
      xMax: length,
    },
    gain: 100.0 / maxProjection, // convert to percentages
  });
};

export const stationarityVarianceBytesStorageDescriptor = (id: string, minFrequency: number, maxFrequency: number) => {
  return makeBinaryField({
    id,
    dbName: ASTRION_INDEXEDDB_NAME,
    storeName: ASTRION_INDEXEDDB_TABLES.stationarityTestVariance,
    dstName: 'data',
    dataType: DataType.Float64,
    additionalFields: {
      yMin: 0, // TODO - set to min frequency once the stationarity min frequency bug is resolved
      yMax: maxFrequency,
    },
  });
};

export const stationarityImagesStorageDescriptor = (
  id: string,
  minDataValue: number,
  maxDataValue: number,
  length: number,
  maxFrequency: number,
  imageMin?: number,
  imageMax?: number
) => {
  return makeImageField({
    id,
    dbName: ASTRION_INDEXEDDB_NAME,
    storeName: ASTRION_INDEXEDDB_TABLES.stationarityTestImages,
    dstName: 'data',
    minValue: minDataValue,
    maxValue: maxDataValue,
    additionalFields: {
      xMin: 0.0,
      xMax: length,
      yMin: 0, // TODO - set to min frequency once the stationarity min frequency bug is resolved
      yMax: maxFrequency,
    },
    imageMin,
    imageMax,
  });
};
