import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { LoadingState } from '@shared/interfaces/loading-state';

import { defaultSamplingTestWithLoadingState, ShannonTest } from '../interface/sampling-test';
import { defaultStationarityTestWithLoadingState, Stationarity } from '../interface/stationarity';
import { defaultSaturationTestWithLoadingState, TimeSaturation } from '../interface/time-saturation';

export const featureName = 'data-validation';

export interface DataValidationState {
  signalId: AStrionSignalId | null;
  saturationTest: TimeSaturation;
  samplingTest: ShannonTest;
  stationarityTest: Stationarity;
  progress: ComputationStepProgress;
  loadingState: LoadingState;
  samplingFrequency: number;
  frequencyToHertzRatio: number;
}

export const DATA_VALIDATION_INITIAL_STATE: DataValidationState = {
  signalId: null,
  saturationTest: defaultSaturationTestWithLoadingState(LoadingState.Unknown),
  samplingTest: defaultSamplingTestWithLoadingState(LoadingState.Unknown),
  stationarityTest: defaultStationarityTestWithLoadingState(LoadingState.Unknown),
  progress: ComputationStepProgress.Unknown,
  loadingState: LoadingState.Unknown,
  samplingFrequency: -1,
  frequencyToHertzRatio: 0,
};
