import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { SpectralAnalysis, SpectralAnalysisDto } from '../interface/spectral-analysis';
import { mapCyclesFromDto } from './cycles-mapping';
import { mapFusionFromDto } from './fusion-mapping';
import { mapHarmonicSeriesFromDto } from './harmonic-series-mapping';

export const mapSpectralAnalysisFromDto = (dto: SpectralAnalysisDto): SpectralAnalysis => {
  const samplingFrequency = dto.samplingFrequency;
  const frequencyToHertzRatio = dto.frequencyToHertzRatio;
  const cycles = mapCyclesFromDto(dto.cycles, dto.samplingFrequency);

  return {
    samplingFrequency,
    frequencyToHertzRatio,
    cycles,
    fusion: mapFusionFromDto(dto.fusion),
    harmonicSeries: mapHarmonicSeriesFromDto(dto.harmonicSeries),
    progress: dto.computationDone ? ComputationStepProgress.Completed : ComputationStepProgress.InProgress,
    loadingState: LoadingState.Loaded,
  };
};
