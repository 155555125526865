import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './sensor-signals.reducer';
import { name } from './sensor-signals.state';
import {
  AStrionSignalId,
  AStrionSignalWithStatus,
  AStrionSignal,
  getLength,
} from '@features/signals/shared/interface/astrion-signal.interface';
import { LoadingState } from '@shared/interfaces/loading-state';
import { SamplingCondition } from '../interfaces/sampling-condition.interface';

export interface SignalIdAndTimestamps {
  id: AStrionSignalId;
  dateTime: number;
  createTime: number;
}

export const sensorSignalsFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectSignals, selectStatuses, selectLoadingState }) => {
    const selectSortedSignalsTimestampsAndIds = createSelector(
      selectSignals,
      (signals: AStrionSignal[]): SignalIdAndTimestamps[] =>
        signals
          .filter(signal => !!signal.date)
          .map(signal => ({
            id: signal.id,
            dateTime: signal.date!.getTime(),
            createTime: signal.createdAt.getTime(),
          }))
          .sort((a, b) => (a.dateTime === b.dateTime ? a.createTime - b.createTime : a.dateTime - b.dateTime))
    );

    const selectDuplicateDateSignalId = createSelector(
      selectSortedSignalsTimestampsAndIds,
      (signals: SignalIdAndTimestamps[]): AStrionSignalId[] =>
        signals
          .filter((signal, index, sorted) => index > 0 && sorted[index - 1].dateTime === signal.dateTime)
          .map(s => s.id)
    );

    const selectSortedSignalsUniqueTimestampsAndIds = createSelector(
      selectSortedSignalsTimestampsAndIds,
      selectDuplicateDateSignalId,
      (signals: SignalIdAndTimestamps[], duplicateId: AStrionSignalId[]): SignalIdAndTimestamps[] => {
        const duplicateSet = new Set<AStrionSignalId>(duplicateId);
        return signals.filter(s => !duplicateSet.has(s.id));
      }
    );

    const selectSignalsOrderedByDate = createSelector(selectSignals, signals => {
      return signals.filter(signal => !!signal.date).sort((a, b) => a.date!.getTime() - b.date!.getTime());
    });

    return {
      selectSortedSignalsTimestampsAndIds,
      selectDuplicateDateSignalId,
      selectSortedSignalsUniqueTimestampsAndIds,

      selectIsLoading: createSelector(selectLoadingState, loadingState => loadingState === LoadingState.Loading),

      selectContainsSignal: (signalId: AStrionSignalId) =>
        createSelector(selectSignals, signals => signals.some(s => s.id === signalId)),

      selectSignalsWithStatus: createSelector(
        selectSignals,
        selectStatuses,
        (signals, statuses): AStrionSignalWithStatus[] => {
          return signals.map(signal => ({
            ...signal,
            status: statuses[signal.id],
          }));
        }
      ),

      selectNonStationarityValues: createSelector(selectSignalsOrderedByDate, selectStatuses, (signals, statuses) => {
        return signals
          .map(signal => [
            signal.date!.getTime(),
            statuses[signal.id]?.validationFlags?.nonStationnarity?.percentage ?? NaN,
          ])
          .flat();
      }),

      selectSpeedValues: createSelector(selectSignalsOrderedByDate, signals => {
        return signals.map(signal => [signal.date!.getTime(), signal.speed ?? NaN]).flat();
      }),

      selectSamplingCondition: createSelector(selectSignals, (signals: AStrionSignal[]): SamplingCondition | null => {
        if (signals.length === 0) {
          return null;
        }
        return {
          samplingFrequency: signals[0].samplingFrequency,
          sampleCount: signals[0].samplesCount,
          frequencyToHertzRatio: signals[0].frequencyToHertzRatio,
          length: getLength(signals[0]),
        };
      }),
    };
  },
});
