import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { emptyProps, props } from '@ngrx/store';

import { Stationarity } from '../../interface/stationarity';

export function stationarityActions() {
  return {
    stationarityTestFetchRequested: props<{ signalId: AStrionSignalId }>(),
    stationarityTestFetched: props<{ signalId: AStrionSignalId; stationarityTest: Stationarity }>(),
    stationarityTestNotFound: props<{ signalId: AStrionSignalId }>(),
    stationarityTestFetchFailed: props<{ signalId: AStrionSignalId }>(),

    stationarityDataVarianceBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      minFrequency: number;
      maxFrequency: number;
      contentPath: string;
    }>(),
    stationarityDataVarianceBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    stationarityDataVarianceBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    stationarityDataVarianceBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    stationarityDataProjectionBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      length: number;
      maxProjection: number;
      contentPath: string;
    }>(),
    stationarityDataProjectionBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    stationarityDataProjectionBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    stationarityDataProjectionBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    stationarityDataImageBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      contentPath: string;
      length: number;
      maxFrequency: number;
      minValue: number;
      maxValue: number;
    }>(),
    stationarityDataImageBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    stationarityDataImageBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    stationarityDataImageBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    stationaritySpectrogramImageBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      contentPath: string;
      length: number;
      maxFrequency: number;
      psdMin: number;
      psdMax: number;
    }>(),
    stationaritySpectrogramImageBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    stationaritySpectrogramImageBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    stationaritySpectrogramImageBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    stationarityTestBytesFetchRejected: emptyProps(),
  };
}
